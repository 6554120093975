<template>
  <div id="wizard-notifications">
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="processCreate"
      >
        <tab-content
          :title="$t('purchases.label3')"
          :before-change="validationForm"
        >
          <validation-observer
            ref="infoNotification"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("purchases.label3") }}
                </h5>
              </b-col>
              <b-col v-if="language == base">
                <validation-provider
                  v-slot="{ errors }"
                  name="nameNotification"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    :invalid-feedback="$t('required')"
                  >
                    <label class="form-label">{{ $t('dataGeneric.name') + ' *' }}</label>
                    <b-form-input
                      id="nameNotification"
                      v-model="nameNotification"
                      maxlength="255"
                      required
                      autocomplete="new-password"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('dataGeneric.name')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    :invalid-feedback="$t('required')"
                  >
                    <label class="form-label">{{ $t('title') + ' *' }}
                    </label>
                    <b-form-input
                      id="title"
                      v-model="title"
                      maxlength="255"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="new-password"
                      :placeholder="$t('title')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <image-element-vue
                :md-length="3"
                :image-origin="imageData"
                :image-type="'imageData' + language"
                :label="$t('Image')"
                @saveImage="loadImage"
              />
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          :title="$t('configNot')"
          :before-change="validationFormConfi"
        >
          <validation-observer
            ref="confi"
            tag="form"
            class="mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t('configNot') }}
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label="$t('code.type')">
                  <b-form-select
                    id="typeSelected"
                    v-model="typeSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="typeNotifications"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="typeSelected == 'TEX'">
              <!-- TEXTO -->
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('notificationPush.text') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      rows="5"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('notificationPush.text')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="typeSelected == 'URL'">
              <!-- URL -->
              <b-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="url"
                  rules="required|url"
                >
                  <b-form-group
                    :label="$t('notificationPush.url') + ' *'"
                    :state="errors.length > 0 ? false : null"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="url"
                      v-model="url"
                      maxlength="255"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://www.ejemplo.com"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('notificationPush.text') + ' *'"
                    :invalid-feedback="'Texto requerido'"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      rows="5"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('notificationPush.text')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="typeSelected == 'CON'">
              <b-col
                v-if="language == base"
                cols="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="content"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('purchases.content') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div class="d-flex">
                      <b-form-input
                        id="content"
                        v-model="contentName"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('purchases.content')"
                      />
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="$refs['cont-modal'].show()"
                      >
                        {{ $t('select') }}
                      </b-button>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('notificationPush.text') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      rows="5"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('notificationPush.text')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="typeSelected == 'CAC'">
              <b-col v-if="language == base">
                <validation-provider
                  v-slot="{ errors }"
                  name="category"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('catCont') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div class="d-flex">
                      <b-form-input
                        id="category"
                        v-model="categoryName"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('catCont')"
                      />
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="$refs['cat-modal'].show()"
                      >
                        {{ $t('select') }}
                      </b-button>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('notificationPush.text') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      :state="errors.length > 0 ? false : null"
                      rows="5"
                      :placeholder="$t('notificationPush.text')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="typeSelected == 'CAP'">
              <b-col
                v-if="language == base"
                cols="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="categoryProd"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('slider.productCategory') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div class="d-flex">
                      <b-form-input
                        id="categoryProd"
                        v-model="categoryProdName"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('slider.productCategory')"
                      />
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="$refs['cat-product-modal'].show()"
                      >
                        {{ $t('select') }}
                      </b-button>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('notificationPush.text') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      :state="errors.length > 0 ? false : null"
                      rows="5"
                      :placeholder="$t('notificationPush.text')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="typeSelected == 'PRO'">
              <b-col
                v-if="language == base"
                cols="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="product"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('producto') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div class="d-flex">
                      <b-form-input
                        id="product"
                        v-model="productName"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('producto')"
                      />
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="$refs['product-modal'].show()"
                      >
                        {{ $t('select') }}
                      </b-button>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('notificationPush.text') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="description"
                      rows="5"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('notificationPush.text')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="language == base">
              <b-col>
                <b-form-group :label="$t('dispositivos')">
                  <b-form-select
                    id="target"
                    v-model="target"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="typeTarget"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('typeSends')">
                  <b-form-select
                    id="sendType"
                    v-model="sendType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="typeSended"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="sendType === 'CRON' && language == base">
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="dateSend"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('dateSend') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <flat-pickr
                      id="example-datepicker"
                      v-model="sendDate"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('dateSend')"
                      :config="{
                        enableTime: true,
                        enableSeconds: true,
                        dateFormat: 'Y-m-d H:i:S',
                        minDate: minDate
                      }"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-form-group
              v-if="language == base"
              :label="$t('sendToUsers')"
            >
              <b-row>
                <b-col>
                  <b-form-select
                    v-model="userTarget"
                    :options="userTargetOptions"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <b-row
              v-if="language == base && userData.isSuperuser"
              cols="6"
            >
              <b-col>
                <b-form-group :label="$t('notification.priority')">
                  <b-form-select
                    id="prioritySelected"
                    v-model="prioritySelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="typePriority"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('notification.ttl')"
                >
                  <b-form-timepicker
                    id="fTime"
                    v-model="ttl"
                    show-seconds
                    locale="es"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="language == base">
              <b-col cols="2">
                <p>{{ $t('notification.active') }}</p>
              </b-col>
              <b-col>
                <b-form-checkbox
                  v-model="isActive"
                  switch
                  name="is-active"
                />
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <template #finish>
          <div>
            <b-button
              :hidden="language != base"
              type="submit"
              variant="primary"
            >
              {{ $t('dataGeneric.create') }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </b-overlay>

    <b-modal
      ref="cont-modal"
      size="xl"
      hide-footer
      :title="$t('modal.content')"
    >
      <div class="d-block text-center">
        <content-selector @info="SelectContent" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>

    <b-modal
      ref="cat-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="categorySelectorTable"
          @confirm-selection="SelectCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>

    <b-modal
      id="product-modal"
      ref="product-modal"
      hide-footer
      size="xl"
      :title="$t('code.sel')"
    >
      <div class="d-block text-center">
        <product-selector
          :columns="categorySelectorTable"
          @confirm-selection="SelectProduct"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['product-platform'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>

    <b-modal
      ref="cat-product-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-product-selector
          :is-final="false"
          :columns="categorySelectorTable"
          @confirm-selection="SelectCategoryProduct"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BFormCheckbox,
  BRow,
  BCol,
  BOverlay,
  BFormTimepicker,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, showToast, camelToSnake,
} from '@/store/functions'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import ProductSelector from '@/views/common/ProductSelector.vue'
import CategoryProductSelector from '@/views/common/CategoryProductSelector.vue'
import ContentSelector from '@/views/common/ContentSelector.vue'
import CategorySelector from '@/views/common/CategorySelector.vue'
import ImageElementVue from '@/views/components/ImageElement.vue'

export default {
  components: {
    ContentSelector,
    CategorySelector,
    FormWizard,
    TabContent,
    BFormTextarea,
    ProductSelector,
    CategoryProductSelector,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ImageElementVue,
    BFormCheckbox,
    BButton,
    flatPickr,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker,
  },
  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      viewName: this.$t('creatNot'),
      viewDetail: this.$t('configNot'),
      nameNotification: '',
      title: '',
      description: '',
      url: '',
      sendDate: null,
      contentName: null,
      userTarget: 'ALL',
      contentId: null,
      image: null,
      target: 'ALL',
      categoryName: null,
      categoryId: null,
      categoryProdName: null,
      categoryProdId: null,
      productName: null,
      productId: null,
      required,
      userData: getUserData(),
      imageDataSrc: null,
      imageSelected: false,
      imageData: null,
      minDate: null,
      typeSelected: 'TEX',
      sendType: 'AUTO',
      userTargetOptions: {
        ALL: this.$t('allUser'),
        REG: this.$t('register'),
        UNR: this.$t('noRegister'),
      },
      typeNotifications: {
        TEX: this.$t('resources.scenarioText'),
        URL: 'URL',
        CON: this.$t('contents.content'),
        CAC: this.$t('contents.category'),
        CAP: this.$t('slider.productCategory'),
        PRO: this.$t('producto'),
      },
      typeSended: {
        AUTO: this.$t('interactivities.auto'),
        CRON: this.$t('programado'),
      },
      typeTarget: {
        ALL: this.$t('Todos'),
        IOF: 'iOS GCM',
        IOS: 'iOS',
        AND: 'Android',
      },
      prioritySelected: 'H',
      typePriority: {
        N: this.$t('notificationPriority.normal'),
        H: this.$t('notificationPriority.high'),
      },
      ttl: '02:00:00',
      platformId: getUserData().profile.client.id,
      categorySelectorTable: [
        { key: 'image', label: this.$t('purchases.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
      ],
      show: false,
      isActive: false,
    }
  },

  mounted() {
    const fechaActual = new Date()
    fechaActual.setTime(fechaActual.getTime() + 5 * 60 * 1000)
    this.minDate = fechaActual
    this.sendDate = this.minDate
    this.$emit('return', {
      name: ' ',
      viewName: this.viewName,
      viewDetail: this.viewDetail,
    })
  },
  methods: {
    goBack() {
      window.history.back()
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `imageData${this.language}`:
          this.imageData = data

          break

        default:
          break
      }
    },
    processCreate() {
      this.$emit('create')
    },
    formSubmitted(components) {
      this.show = true
      const { id } = this.userData.profile.client
      const user = this.userData.id
      let typeId = ''
      switch (this.typeSelected) {
        case 'CON':
          typeId = this.contentId
          break
        case 'CAC':
          typeId = this.categoryId
          break
        case 'CAP':
          typeId = this.categoryProdId
          break
        case 'PRO':
          typeId = this.productId
          break
        default:
          typeId = undefined
          break
      }

      const data = new FormData()
      const objectVariables = []

      components.forEach((childComponent, i) => {
        const { language } = childComponent

        const { title } = childComponent
        const { description } = childComponent
        const { url } = childComponent

        const { imageData } = childComponent
        if (i === 0) {
          objectVariables.push(
            { title: 'description', value: description, type: 'String!' },
          )
        }
        objectVariables.push(
          { title: `title${language}`, value: title, type: 'String' },
          { title: `description${language}`, value: description, type: 'String' },
        )
        data.append(`image_${camelToSnake(language)}`, imageData)

        if (this.typeSelected === 'URL') {
          objectVariables.push(
            { title: `resource${language}`, value: url, type: 'String' },
          )
        } else if (typeId !== undefined) {
          objectVariables.push(
            { title: `resource${language}`, value: typeId, type: 'String' },
          )
        }
      })
      objectVariables.push(
        { title: 'name', value: this.nameNotification, type: 'String!' },
        { title: 'notificationType', value: this.typeSelected, type: 'DevicesNotificationPushNotificationTypeChoices' },
        { title: 'target', value: this.target, type: 'DevicesNotificationPushTargetChoices' },
        { title: 'sendDate', value: this.fechaFormato(this.sendDate), type: 'DateTime' },
        { title: 'sendType', value: this.sendType, type: 'DevicesNotificationPushSendTypeChoices' },
        // { title: 'typeId', value: typeId, type: 'String!' },
        { title: 'user', value: user, type: 'ID!' },
        { title: 'userTarget', value: this.userTarget, type: 'DevicesNotificationPushUserTargetChoices' },
        { title: 'client', value: id, type: 'ID!' },
        { title: 'isActive', value: this.isActive, type: 'Boolean!' },
        { title: 'priority', value: this.prioritySelected, type: 'DevicesNotificationPushPriorityChoices' },
      )

      if (this.userData.isSuperuser) {
        objectVariables.push(
          { title: 'ttl', value: this.ttl.toString(), type: 'String' },
        )
      }

      const variables = {}
      let mutation = 'mutation('
      let query = '{createNotificationPush(input:{\n'
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
                notificationPush{
                  id
                  name
                }
              }
            }`
      query = mutation + query

      const config = {
        timeout: 1000 * 60 * 30,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)

          this.goBack()
        }).catch(() => {
          showToast(this.$t('error'), 0, this)
        })
    },
    fechaFormato(value) {
      const fecha = new Date(value)

      return value != null ? fecha : value
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.infoNotification.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormConfi() {
      return new Promise((resolve, reject) => {
        this.$refs.confi.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    SelectContent(data) {
      const [id, name] = data
      this.contentId = id
      this.contentName = name
      this.$refs['cont-modal'].hide()
    },
    SelectCategory(data) {
      this.categoryId = data.node.id
      this.categoryName = data.node.name
      this.$refs['cat-modal'].hide()
    },
    SelectProduct(data) {
      this.productId = data.node.id
      this.productName = data.node.name
      this.$refs['product-modal'].hide()
    },
    SelectCategoryProduct(data) {
      this.categoryProdId = data.node.id
      this.categoryProdName = data.node.name
      this.$refs['cat-product-modal'].hide()
    },
    hideModal() {
      this.$refs['cont-modal'].hide()
      this.$refs['product-modal'].hide()
      this.$refs['cat-product-modal'].hide()
      this.$refs['cat-modal'].hide()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#wizard-notifications .base-image-input {
  display: block;
  /*  width: 200px;
  height: 200px; */
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#wizard-notifications .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#wizard-notifications .dropContainer {
  border: 3px dashed;
}

#wizard-notifications .base-image-input:hover {
  // opacity: 85% !important;
}

#wizard-notifications .file-input {
  display: none;
}

#wizard-notifications .modal {
  overflow-y: auto;
}

#wizard-notifications .tamanio {

  width: auto;
  height: auto;
}
</style>
